export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            <p> 2023, Aguida Paranhos Decorações &copy;. </p> Desenvolvido por: {' '}
            <a href='barackdafarialima.tech' rel='nofollow'>
              BFL Technologies
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
